// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("trix")
require("@rails/actiontext")

import "bootstrap/dist/js/bootstrap"
import "jquery"
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "youtube"
import Partners from "partners"
import Resources from "resources"
import OppReports from "opp_reports"
import PurchaseOrders from "purchase_orders"
import Users from "users"
import AuditLogs from "audit_logs"
import Leads from "leads"
import Navbar from "navbar"
import AjaxSetup from "ajax_setup"
import Scroll from "scroll"
import RebatesRequests from "rebates_requests"
import ImagePreview from "image_preview"
import JihuLeads from "jihu_leads"
import SubmitOnChange from "submit_on_change"
import Registration from "registration"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

$(document).on('turbolinks:load', function() {
  new Partners();
  new Resources();
  new OppReports();
  new Users();
  new Navbar();
  new AjaxSetup();
  new AuditLogs();
  new Leads();
  new Scroll();
  new RebatesRequests();
  new ImagePreview();
  new JihuLeads();
  new SubmitOnChange();
  new Registration();
  new PurchaseOrders();
})
