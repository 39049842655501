export default class SubmitOnChange {
  constructor() {
    this.initJqueryVariables();
    this.eventRegister();
  }

  initJqueryVariables() {
    this.$select = $('select.submitOnChange');
  }

  eventRegister() {
    this.$select.on("change", (e) => {
      e.target.form.submit();
    })
  }
}
