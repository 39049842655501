export default class OppReports {
  constructor() {
    this.initJqueryVariables();
    this.eventRegister();
    this.userCompanyChanged = false;
  }

  initJqueryVariables() {
    this.$newOppReportForm = $('#new-opp-report__form');
    this.$newOppReportFormSubmitBtn = $('#new-opp-report-form__submit');
    this.$editOppReportForm = $('#edit-opp-report__form');
    this.$editOppReportFormSubmitBtn = $('#edit-opp-report-form__submit');
    this.$userCompanyInputBox = $('#user-company-name-from-qcc');
    this.$qccCompanyList = $('#user-company-names__ul');
    this.$internalCommentsInput = $('#opp_report_internal_comments');
    this.$internalCommentsInputSubmit = $('#opp_report_internal_comments_submit');
    this.$commentsInput = $('#opp_report_comments');
    this.$commentsInputSubmit = $('#opp_report_comments_submit');
  }

  eventRegister() {
    // Opp report form validate
    // 首次加载检查
    if(this.$newOppReportForm.length != 0) {
      if(!this.$newOppReportForm[0].checkValidity()) {
        this.$newOppReportFormSubmitBtn.attr('disabled', 'disabled')
      }
    }

    // Opp report form validate
    // 首次加载检查
    if(this.$editOppReportForm.length != 0) {
      if(!this.$editOppReportForm[0].checkValidity()) {
        this.$editOppReportFormSubmitBtn.attr('disabled', 'disabled')
      }
    }

    // 用户输入关键字后，通过企查查查询前十个关联的公司
    this.$userCompanyInputBox.on('change', (e) => {
      // reset the selected company
      this.$userCompanyInputBox.data('company-key', '');
      this.$qccCompanyList.show();
      this.userCompanyChanged = true;
      const keyWord = $(e.currentTarget).val();
      const searchUrl = `/services/qichacha-fuzzy-search?keyword=${keyWord}`;
      $.ajax({
        url: searchUrl,
        type: 'GET',
        dataType: 'json'
      }).done((res) => {
        if (res.length === 0) {
          alert("未找到匹配公司，请重新输入关键字查询！");
          return
        }
        let companyResults = "";
        res.forEach((company) => {
          companyResults += `
            <li class="user-company-item" data-result-key=${company.KeyNo}>${company.Name}</li>
          `
        })
        this.$qccCompanyList.html(companyResults);
        this.$qccCompanyList.get(0).scrollIntoView({block: "center", inline: "nearest"});
      }).fail((res) => {
        alert('查询失败!');
      })
    })

    // 用户从企查查公司列表选择一个
    $(document).on('click', '.user-company-item', (e) => {
      const companyName = e.currentTarget.textContent;
      const companyKey = e.currentTarget.dataset.resultKey;
      this.$userCompanyInputBox.val(companyName).trigger('change');
      // set selected company
      this.$userCompanyInputBox.data('company-key', companyKey);
      this.$userCompanyInputBox.css('border-color', 'unset');
      localStorage.setItem('currentSeletedCompany', companyName);
      this.$qccCompanyList.hide();
    })

    // Opp report form validate
    // form 提交验证
    this.$newOppReportForm.on('change', (e) => {
      if(e.currentTarget.checkValidity()) {
        this.$newOppReportFormSubmitBtn.removeAttr('disabled')
      } else {
        this.$newOppReportFormSubmitBtn.attr('disabled', 'disabled')
      }
    })

    // Opp report form validate
    // form 提交验证
    this.$editOppReportForm.on('change', (e) => {
      if(e.currentTarget.checkValidity()) {
        this.$editOppReportFormSubmitBtn.removeAttr('disabled')
      } else {
        this.$editOppReportFormSubmitBtn.attr('disabled', 'disabled')
      }
    })

    // 新建商机的时候，只要没有从企查查选择公司就不能提交
    this.$newOppReportForm.on('submit', (e) => {
      const currentSelectedCompanyInStore = localStorage.getItem('currentSeletedCompany');
      const currentSelectedCompanyOnPage = this.$userCompanyInputBox.val();
      if (currentSelectedCompanyInStore === currentSelectedCompanyOnPage) {
        return true;
      }
      if (this.isBlank(this.$userCompanyInputBox.data('company-key'))) {
        alert("请选择有效的公司（客户名称）！");
        this.$userCompanyInputBox.css('border-color', 'red');
        this.$qccCompanyList.get(0).scrollIntoView({block: "center", inline: "nearest"});
        return false;
      }
    })

    // 更新商机的时候，允许保持原值，但是如果值变化了，则必须从企查查选择
    this.$editOppReportForm.on('submit', (e) => {
      if (this.userCompanyChanged && this.isBlank(this.$userCompanyInputBox.data('company-key'))) {
        alert("请选择有效的公司（客户名称）！");
        this.$userCompanyInputBox.css('border-color', 'red');
        this.$qccCompanyList.get(0).scrollIntoView({block: "center", inline: "nearest"});
        return false;
      }
    })

    this.$newOppReportFormSubmitBtn.on('click', (e) => {
      this.$newOppReportForm.submit();
    })

    this.$editOppReportFormSubmitBtn.on('click', (e) => {
      this.$editOppReportForm.submit();
    })

    this.$internalCommentsInput.on('change', (e) => {
      this.$internalCommentsInputSubmit.prop("disabled", false);
    })
  }

  isBlank(str) {
    return (!str || /^\s*$/.test(str));
  }
}
