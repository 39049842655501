export default class JihuLeads {
  constructor() {
    this.initJqueryVariables();
    this.eventRegister();
  }

  initJqueryVariables() {
    this.$mainCheckbox = $('#js-main-checkbox');
    this.$childrenCheckboxes = $("input[class='js-children-checkboxes']:not(:disabled)");
    this.$batchAssignBtn = $("#js-batch-assign-btn")
    this.$jihuLeadsUuidsInput = $("#js-jihu-leads-uuids-input")
  }

  eventRegister() {
    this.$mainCheckbox.on("click", (e) => {
      if (e.target.checked) {
        this.selectedAllCheckboxes()
        this.enableBatchAssignBtn()
      } else {
        this.unSelectedAllCheckboxes()
        this.disableBatchAssignBtn()
      }
      this.setUuids()
    })

    this.$childrenCheckboxes.on('click', (e) => {
      const checkedChildrenCheckboxesLength = this.getCheckedChildrenCheckboxes().length
      if (checkedChildrenCheckboxesLength == 0) {
        this.unSelectedMainCheckbox()
        this.disableBatchAssignBtn()
      } else if (checkedChildrenCheckboxesLength >= this.$childrenCheckboxes.length ) {
        this.selectedMainCheckbox()
        this.enableBatchAssignBtn()
      } else {
        this.indeterminateMainCheckbox()
        this.enableBatchAssignBtn()
      }
      this.setUuids()
    })
  }

  // 获取所有选中的 checkboxes
  getCheckedChildrenCheckboxes() {
    return $("input[class='js-children-checkboxes']:not(:disabled):checked")
  }

  // 子选择器全选
  selectedAllCheckboxes() {
    this.$childrenCheckboxes.prop("checked", true)
  }

  // 子选择器取消全选
  unSelectedAllCheckboxes() {
    this.$childrenCheckboxes.prop("checked", false)
  }

  // 总选择器选中
  selectedMainCheckbox() {
    this.removeIndeterminateMainCheckbox()
    this.$mainCheckbox.prop("checked", true)
  }

  // 总选择器取消选中
  unSelectedMainCheckbox() {
    this.removeIndeterminateMainCheckbox()
    this.$mainCheckbox.prop("checked", false)
  }

  // 总选择器 indeterminate
  indeterminateMainCheckbox() {
    this.$mainCheckbox.prop('indeterminate', true)
  }

  // 总选择器移除 indeterminate
  removeIndeterminateMainCheckbox() {
    this.$mainCheckbox.prop('indeterminate', false)
  }

  disableBatchAssignBtn() {
    this.$batchAssignBtn.attr("disabled", true)
  }

  enableBatchAssignBtn() {
    this.$batchAssignBtn.attr("disabled", false)
  }

  setUuids() {
    const checkedUuids = [];
    this.getCheckedChildrenCheckboxes().each((index, e) => {
      checkedUuids.push(e.value)
    })
    this.$jihuLeadsUuidsInput.val(checkedUuids)
  }
}
