export default class PurchaseOrders {
  constructor() {
    this.initJqueryVariables();
    this.eventRegister();
    this.endUserCompanyChanged = false;
  }

  initJqueryVariables() {
    this.$endUserCompanyInputBox = $('#end-user-company-name-from-qcc');
    this.$purchaseOrderFormSubmitBtn = $('#purchase-order-form__submit');
    this.$qccCompanyList = $('#end-user-company-names__ul');
    this.$purchaseOrderForm = $('#purchase-order-form');
  }

  eventRegister() {
    // 点击按钮，提交表单
    this.$purchaseOrderFormSubmitBtn.on('click', (e) => {
      this.$purchaseOrderForm.submit();
    })

    // 新建商机的时候，只要没有从企查查选择公司就不能提交
    // 针对香港总代，有些公司信息在企查查可能查不到，所以需要 disable 这个功能
    //this.$purchaseOrderForm.on('submit', (e) => {
      //const currentSelectedCompanyInStore = localStorage.getItem('poCurrentSeletedCompany');
      //const currentSelectedCompanyOnPage = this.$endUserCompanyInputBox.val();
      //if (currentSelectedCompanyInStore === currentSelectedCompanyOnPage) {
        //return true;
      //}
      //if (this.isBlank(this.$endUserCompanyInputBox.data('company-key'))) {
        //alert("请选择有效的公司（客户名称）！");
        //this.$endUserCompanyInputBox.css('border-color', 'red');
        //this.$qccCompanyList.get(0).scrollIntoView({block: "center", inline: "nearest"});
        //return false;
      //}
    //})

    // form 首次加载验证
    if(this.$purchaseOrderForm.length != 0) {
      if(!this.$purchaseOrderForm[0].checkValidity()) {
        this.$purchaseOrderFormSubmitBtn.attr('disabled', 'disabled')
      }
    }

    // form 提交验证
    this.$purchaseOrderForm.on('change', (e) => {
      if(e.currentTarget.checkValidity()) {
        this.$purchaseOrderFormSubmitBtn.removeAttr('disabled')
      } else {
        this.$purchaseOrderFormSubmitBtn.attr('disabled', 'disabled')
      }
    })

    // 异步生成文件，然后再下载
    // deprecated 2022-12-09
    $('#export-purchase-orders').on('click', (e) => {
      e.preventDefault();
      $.ajax({
        method: 'GET',
        dataType: 'json',
        url: 'purchase_orders.json'
      }).done((response) => {
        window.location = `/exports/download?file_name=${response.exported_file_name}`;
      }).fail((msg) => {
        alert('Export Failed');
      })
    })
  }

  isBlank(str) {
    return (!str || /^\s*$/.test(str))
  }
}
