export default class Scroll {
  constructor() {
    localStorage.setItem('partnersCurrentPage', 1);
    localStorage.setItem('oppReportsCurrentPage', 1);
    localStorage.setItem('leadsCurrentPage', 1);
    localStorage.setItem('auditLogsCurrentPage', 1);
    localStorage.setItem('usersCurrentPage', 1);
    localStorage.setItem('pointsCurrentPage', 1);
    localStorage.setItem('bonusesCurrentPage', 1);
    localStorage.setItem('jihuLeadsCurrentPage', 1);
    localStorage.setItem('jihuLeadTagsCurrentPage', 1);
    localStorage.setItem('partnerUserRequestsCurrentPage', 1);
    this.initJqueryVariables();
    this.eventRegister();
    this.partnersLoading = false;
  }

  initJqueryVariables() {
  }

  //$('.table-header').slideUp('slow');
  //$('.table-header').slideDown('slow');
  //$('.opp-reports-index--list__div .table-header').hide();
  //$('.opp-reports-index--list__div .table-header').show();
  eventRegister() {
    // Notes: better to keep for a reference
    //let scrollRegistered = false;
    //if (!!$._data(window, "events") && !!$._data(window, "events").scroll) {
      //$._data(window, "events").scroll.forEach(event => {
        //if (event.handler.name === 'tryLoadIndexData') {
          //scrollRegistered = true;
        //}
      //})
    //}
    //if (!scrollRegistered) {
      //const tryLoadIndexData = (e) => { this.loadIndexData() };
      //$('body').on('touchmove', tryLoadIndexData)
    //}
    $('body').on('touchmove', (e) => {
      const yOffset = window.pageYOffset || document.documentElement.scrollTop;
      if ( yOffset > 0) {
        $('.scroll-top__div').show();
      } else {
        $('.scroll-top__div').hide();
      }
    })

    $(window).on('scroll', (e) => {
      const yOffset = window.pageYOffset || document.documentElement.scrollTop;
      if ( yOffset > 0) {
        $('.scroll-top__div').show();
      } else {
        $('.scroll-top__div').hide();
      }
    })

    $('.scroll-top__div').on('click', (e) => {
      // 设置滚动行为改为平滑的滚动
      window.scrollTo({
          top: 0,
          behavior: "smooth"
      });
    })

    $('#load-more__p').on('click', (e) => {
      this.clickToLoadIndexData()
    })
  }

  clickToLoadIndexData() {
    if ($('.opp-reports').length == 1) {
      this.loadOppReportsForMobile();
    }
    if ($('.partners').length == 1) {
      this.loadPartnersForMobile();
    }
    if ($('.leads').length == 1) {
      this.loadLeadsForMobile();
    }
    if ($('.audit-logs').length == 1) {
      this.loadAuditLogsForMobile();
    }
    if ($('.users').length == 1) {
      this.loadUsersForMobile();
    }
    if ($('.points').length == 1) {
      this.loadPointsForMobile();
    }
    if ($('.bonuses').length == 1) {
      this.loadBonusesForMobile();
    }
    if ($('.jihu_leads').length == 1) {
      this.loadJihuLeadsForMobile();
    }
    if ($('.jihu_lead_tags').length == 1) {
      this.loadJihuLeadTagsForMobile();
    }
    if ($('.partner_user_requests').length == 1) {
      this.loadPartnerUserRequestsForMobile();
    }
  }

  loadIndexData() {
    const yOffset = window.pageYOffset || document.documentElement.scrollTop;
    if ( yOffset > 0) {
      if($(window).scrollTop() + $(window).height() == $(document).height()) {
        if ($('.opp-reports').length == 1) {
          this.loadOppReportsForMobile();
        }
        if ($('.partners').length == 1) {
          this.loadPartnersForMobile();
        }
        if ($('.leads').length == 1) {
          this.loadLeadsForMobile();
        }
        if ($('.audit-logs').length == 1) {
          this.loadAuditLogsForMobile();
        }
        if ($('.points').length == 1) {
          this.loadPointsForMobile();
        }
        if ($('.bonuses').length == 1) {
          this.loadBonusesForMobile();
        }
        if ($('.jihu_leads').length == 1) {
          this.loadJihuLeadsForMobile();
        }
        if ($('.jihu_lead_tags').length == 1) {
          this.loadJihuLeadTagsForMobile();
        }
        if ($('.jihu_lead_tags').length == 1) {
          this.loadPartnerUserRequestsForMobile();
        }
      }
    }
  }

  loadPartnersForMobile() {
    if (window.innerWidth <= 768 && !this.partnersLoading) {
      if ($('.partners .table__div tbody').length == 1) {
        this.partnersLoading = true;
        const currentPage = parseInt(localStorage.getItem('partnersCurrentPage'));
        localStorage.setItem('partnersCurrentPage', currentPage + 1);
        $.ajax({
          method: 'GET',
          dataType: 'script',
          url: `/partners?page=${currentPage + 1}`
        }).done(() => {
          this.partnersLoading = false;
        })
      }
    }
  }

  loadOppReportsForMobile() {
    if (window.innerWidth <= 768) {
      if ($('.opp-reports .table__div tbody').length == 1) {
        const currentPage = parseInt(localStorage.getItem('oppReportsCurrentPage'));
        localStorage.setItem('oppReportsCurrentPage', currentPage + 1);
        $.ajax({
          method: 'GET',
          dataType: 'script',
          url: `/opportunity_reports?page=${currentPage + 1}`
        }).done(() => {
          console.log('yes')
        })
      }
    }
  }

  loadLeadsForMobile() {
    if (window.innerWidth <= 768) {
      if ($('.leads .table__div tbody').length == 1) {
        const currentPage = parseInt(localStorage.getItem('leadsCurrentPage'));
        localStorage.setItem('leadsCurrentPage', currentPage + 1);
        $.ajax({
          method: 'GET',
          dataType: 'script',
          url: `/leads?page=${currentPage + 1}`
        }).done(() => {
          console.log('yes')
        })
      }
    }
  }

  loadPointsForMobile() {
    if (window.innerWidth <= 768) {
      if ($('.points .table__div tbody').length == 1) {
        const currentPage = parseInt(localStorage.getItem('pointsCurrentPage'));
        localStorage.setItem('pointsCurrentPage', currentPage + 1);
        $.ajax({
          method: 'GET',
          dataType: 'script',
          url: `/points?page=${currentPage + 1}`
        }).done(() => {
          console.log('yes')
        })
      }
    }
  }

  loadBonusesForMobile() {
    if (window.innerWidth <= 768) {
      if ($('.bonuses .table__div tbody').length == 1) {
        const currentPage = parseInt(localStorage.getItem('bonusesCurrentPage'));
        localStorage.setItem('bonusesCurrentPage', currentPage + 1);
        $.ajax({
          method: 'GET',
          dataType: 'script',
          url: `/bonuses?page=${currentPage + 1}`
        }).done(() => {
          console.log('yes')
        })
      }
    }
  }

  loadJihuLeadsForMobile() {
    if (window.innerWidth <= 768) {
      if ($('.jihu_leads .table__div tbody').length == 1) {
        const currentPage = parseInt(localStorage.getItem('jihuLeadsCurrentPage'));
        localStorage.setItem('jihuLeadsCurrentPage', currentPage + 1);
        $.ajax({
          method: 'GET',
          dataType: 'script',
          url: `/jihu_leads?page=${currentPage + 1}`
        }).done(() => {
          console.log('yes')
        })
      }
    }
  }

  loadJihuLeadTagsForMobile() {
    if (window.innerWidth <= 768) {
      if ($('.jihu_lead_tags .table__div tbody').length == 1) {
        const currentPage = parseInt(localStorage.getItem('jihuLeadTagsCurrentPage'));
        localStorage.setItem('jihuLeadTagsCurrentPage', currentPage + 1);
        $.ajax({
          method: 'GET',
          dataType: 'script',
          url: `/jihu_lead_tags?page=${currentPage + 1}`
        }).done(() => {
          console.log('yes')
        })
      }
    }
  }

  loadPartnerUserRequestsForMobile() {
    if (window.innerWidth <= 768) {
      if ($('.partner_user_requests .table__div tbody').length == 1) {
        const currentPage = parseInt(localStorage.getItem('partnerUserRequestsCurrentPage'));
        localStorage.setItem('partnerUserRequestsCurrentPage', currentPage + 1);
        $.ajax({
          method: 'GET',
          dataType: 'script',
          url: `/partner_user_requests?page=${currentPage + 1}`
        }).done(() => {
          console.log('yes')
        })
      }
    }
  }

  loadAuditLogsForMobile() {
    if (window.innerWidth <= 768) {
      if ($('.audit-logs .table__div tbody').length == 1) {
        const currentPage = parseInt(localStorage.getItem('auditLogsCurrentPage'));
        localStorage.setItem('auditLogsCurrentPage', currentPage + 1);
        $.ajax({
          method: 'GET',
          dataType: 'script',
          url: `/audit_logs?page=${currentPage + 1}`
        }).done(() => {
          console.log('yes')
        })
      }
    }
  }
  loadUsersForMobile() {
    if (window.innerWidth <= 768) {
      if ($('.users .table__div tbody').length == 1) {
        const currentPage = parseInt(localStorage.getItem('usersCurrentPage'));
        localStorage.setItem('usersCurrentPage', currentPage + 1);
        $.ajax({
          method: 'GET',
          dataType: 'script',
          url: `/users?page=${currentPage + 1}`
        }).done(() => {
          console.log('yes')
        })
      }
    }
  }
}
