export default class AuditLogs {
  constructor() {
    this.eventRegister();
  }

  eventRegister() {
    // For Mobile
    //$(document.body).on('touchmove', () => { this.scrollEffects() });
    //$(window).on('scroll', () => { this.scrollEffects() })
  }

  scrollEffects() {
  }
}
