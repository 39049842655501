export default class RebatesRequests {
  constructor() {
    this.initJqueryVariables();
    this.eventRegister();
  }

  initJqueryVariables() {
    this.$rebatesRequestModalDom = $('#rebatesRequestModal');
    this.$openRebatesRequestModal = $('#openRebatesRequestModal')
    this.$closeRebatesRequestModal = $('#closeRebatesRequestModal')
  }

  eventRegister() {
    this.$openRebatesRequestModal.on("click", (e) => {
      e.stopPropagation();
      this.$rebatesRequestModalDom.modal('show');
    })

    this.$closeRebatesRequestModal.on("click", (e) => {
      e.stopPropagation();
      this.$rebatesRequestModalDom.modal('hide');
    })
  }
}
