export default class AjaxSetup {
  constructor() {
    this.initJqueryVariables();
    this.eventRegister();
  }

  initJqueryVariables() {
    this.loadingOverlay = $('.loading-overlay');
  }

  eventRegister() {
    $.ajaxSetup({
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    });

    $(document).on({
      ajaxStart: () => this.loadingOverlay.addClass('show'),
      ajaxStop: () => this.loadingOverlay.removeClass('show')
    });

    // This is for Rails-ujs event handlers
    // not for regular jQuery ajax event handlers
    $(document).on({
      'ajax:beforeSend': () => this.loadingOverlay.addClass('show'),
      'ajax:complete': () => this.loadingOverlay.removeClass('show')
    });
  }
}
