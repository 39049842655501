export default class registration {
  constructor() {
    this.initJqueryVariables();
    this.companyNameCorrectness = false;
    this.phoneCorrectness = false;
    this.emailCorrectness = false;
    this.eventRegister();
  }

  initJqueryVariables() {
    this.$companyNameInputDom = $('#new_partner_user_request [name*=company_name]');
    this.$phoneInputDom = $('#new_partner_user_request [name*=phone]');
    this.$emailInputDom = $('#new_partner_user_request [name*=email]');
    this.$emailErrorNotices = $('#new_partner_user_request .error-notice[id*=email]');
    this.$phoneErrorNotices = $('#new_partner_user_request .error-notice[id*=phone]');
    this.$companyErrorNotices = $('#new_partner_user_request .error-notice[id*=company]');
    this.$submitBtn = $('#new_partner_user_request [type=submit]');
  }

  eventRegister() {
    this.$emailInputDom.on("change", (e) => {
      this.validEmail()
      this.checkSubmitDisable()
    })
    this.$companyNameInputDom.on("change", (e) => {
      this.validCompanyName()
      this.checkSubmitDisable()
    })
    this.$phoneInputDom.on("keyup", (e) => {
      this.validPhone()
      this.checkSubmitDisable()
    })
  }

  validCompanyName() {
    if (this.validInfo('company_name', this.$companyNameInputDom.val())) {
      this.companyNameCorrectness = true;
      return true
    } else {
      this.companyNameCorrectness = false;
      return false
    }
  }

  validEmail() {
    if (this.validInfo('email', this.$emailInputDom.val())) {
      this.emailCorrectness = true
      return true
    } else {
      this.emailCorrectness = false
      return false
    }
  }

  validPhone() {
    if (this.validPhoneFormat() && this.validInfo('phone', this.$phoneInputDom.val())) {
      this.phoneCorrectness = true
      return true
    } else {
      this.phoneCorrectness = false
      return false
    }
  }

  validPhoneFormat() {
    if (/^1[3-9]\d{9}$/.test(this.$phoneInputDom.val())) {
      $(this.$phoneInputDom.data('notice')).hide();
      return true
    } else {
      $(this.$phoneInputDom.data('notice')).show();
      return false
    }
  }

  // valid email, phone or company request
  validInfo(type, value) {
    const validUrl = '/users/validate_info';
    const data = { type, value }
    let result;
    $.ajax({
      url: validUrl,
      type: 'POST',
      dataType: 'json',
      async: false,
      data: data,
    }).done((res) => {
      if (res.success) {
        this.hideNotice(type)
        result = true
      } else {
        result = false
        $(res.target_id).show();
      }
    }).fail((res) => {
      alert('验证失败，请刷新页面重试！');
    })
    return result
  }

  hideNotice(type) {
    switch (type) {
      case 'email':
        this.$emailErrorNotices.hide();
        break;
      case 'phone':
        this.$phoneErrorNotices.hide();
        break;
      case 'company_name':
        this.$companyErrorNotices.hide();
        break;
    }
  }

  checkSubmitDisable() {
    if (this.companyNameCorrectness && this.phoneCorrectness && this.emailCorrectness) {
      this.$submitBtn.prop('disabled', false)
    } else {
      this.$submitBtn.prop('disabled', true)
    }
  }
}
