export default class Resources {
  constructor() {
    this.slideIndex = 1;
    this.initJqueryVariables();
    this.eventRegister();
    this.showDivs(this.slideIndex);
  }

  initJqueryVariables() {
    this.$resourcesHeroCarouselLeftArrow = $('#resources-hero-carousel-left-arrow');
    this.$resourcesHeroCarouselRightArrow = $('#resources-hero-carousel-right-arrow');
    this.$resourcesHeroCarouselMySlides = $('.resources-hero-carousel-mySlides__div');
    this.$resourcesHeroCarouselMySlidesMobile = $('.resources-hero-carousel-mySlides-mobile__div');
    this.$resourcesHeroCarouselIndicators = $('.resources-hero-carousel-indicator__div');
    this.$resourcesHeroCarouselIndicatorsMobile = $('.resources-hero-carousel-indicator-mobile__div');
  }

  eventRegister() {
    $('.resources-hero-carousel-mySlides-mobile__div .resources-text__div').on('touchend', () => {
      this.plusDivs(1);
    });
    this.$resourcesHeroCarouselLeftArrow.on('click', () => {
      this.plusDivs(-1);
    });
    this.$resourcesHeroCarouselRightArrow.on('click', () => {
      this.plusDivs(1);
    });
    $('.resources-video-play__img').on('click', (e) => {
      $(`#${e.currentTarget.dataset.modalId}`).show();
      $('body').css('overflow', 'hidden');
    });
    $('.resources-cover__div').on('click', (e) => {
      $(`#${e.currentTarget.dataset.modalId}`).show();
      $('body').css('overflow', 'hidden');
    });
    $('.resources-video-modal-close_span').on('click', (e) => {
      $('body').css('overflow', 'scroll');
      $(`#${e.currentTarget.dataset.modalId}`).hide();
      const iframe = $(`#${e.currentTarget.dataset.modalId}`).find('iframe');
      const src= iframe.attr('src');
      iframe.attr('src', src);
    });
  }

  showDivs(n) {
    if (location.pathname != '/resources') {
      return;
    } else {
      if (window.innerWidth <= 768) {
        this.showDivsMobile(n);
      } else {
        this.showDivsDesktop(n);
      }
    }
  }

  showDivsMobile(n) {
    if (n > this.$resourcesHeroCarouselMySlidesMobile.length) {
      this.slideIndex = 1;
    } else {
      this.slideIndex = n;
    }

    for (let i = 0; i < this.$resourcesHeroCarouselMySlidesMobile.length; i++) {
      this.$resourcesHeroCarouselMySlidesMobile[i].style.display = "none";
    }
    for (let i = 0; i < this.$resourcesHeroCarouselIndicatorsMobile.length; i++) {
     this.$resourcesHeroCarouselIndicatorsMobile[i].classList.remove("active");
    }
    $(this.$resourcesHeroCarouselMySlidesMobile[this.slideIndex-1]).show();

    this.$resourcesHeroCarouselIndicatorsMobile[this.slideIndex-1].classList.add("active");
  }

  showDivsDesktop(n) {
    this.$resourcesHeroCarouselLeftArrow.removeClass('inactive');
    this.$resourcesHeroCarouselRightArrow.removeClass('inactive');

    if (n >= this.$resourcesHeroCarouselMySlides.length) {
      this.slideIndex = this.$resourcesHeroCarouselMySlides.length;
      this.$resourcesHeroCarouselRightArrow.addClass('inactive');
    } else if (n <= 1) {
      this.slideIndex = 1;
      this.$resourcesHeroCarouselLeftArrow.addClass('inactive');
    } else {
      this.slideIndex = n;
    }

    for (let i = 0; i < this.$resourcesHeroCarouselMySlides.length; i++) {
      this.$resourcesHeroCarouselMySlides[i].style.display = "none";
    }
    for (let i = 0; i < this.$resourcesHeroCarouselIndicators.length; i++) {
     this.$resourcesHeroCarouselIndicators[i].classList.remove("active");
    }
    this.$resourcesHeroCarouselMySlides[this.slideIndex-1].style.display = "flex";
    this.$resourcesHeroCarouselIndicators[this.slideIndex-1].classList.add("active");
  }

  plusDivs(n) {
    this.showDivs(this.slideIndex + n);
  }
}
