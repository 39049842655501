export default class Partners {
  constructor() {
    this.initJqueryVariables();
    this.eventRegister();
  }

  initJqueryVariables() {
    this.$newPartnerForm = $('#new-partner__form');
    this.$newPartnerFormSubmitBtn = $('#new-partner-form__submit');
    this.$editPartnerForm = $('#edit-partner__form');
    this.$editPartnerFormSubmitBtn = $('#edit-partner-form__submit');
  }

  eventRegister() {
    // logo preview
    $('#partner-logo__input').on('change', (e) => {
      $('#partner-logo__img').attr('src', URL.createObjectURL(e.currentTarget.files[0]));
    })

    // show password in partner detail page
    $('.partner-show__btn-show-pwd').on('click', (e) => {
      const partnerUuid= e.currentTarget.dataset.partnerUuid
      const passwordClass = `password${partnerUuid}`
      $(`#${passwordClass}`).show()
      $(e.currentTarget).hide()
    })

    // Partner form validate
    if(this.$newPartnerForm.length != 0) {
      if(!this.$newPartnerForm[0].checkValidity()) {
        this.$newPartnerFormSubmitBtn.attr('disabled', 'disabled')
      }
    }

    // Partner form validate
    if(this.$editPartnerForm.length != 0) {
      if(!this.$editPartnerForm[0].checkValidity()) {
        this.$editPartnerFormSubmitBtn.attr('disabled', 'disabled')
      }
    }

    // Partner form validate
    this.$newPartnerForm.on('change', (e) => {
      if(e.currentTarget.checkValidity()) {
        this.$newPartnerFormSubmitBtn.removeAttr('disabled')
      } else {
        this.$newPartnerFormSubmitBtn.attr('disabled', 'disabled')
      }
    })

    // Partner form validate
    this.$editPartnerForm.on('change', (e) => {
      if(e.currentTarget.checkValidity()) {
        this.$editPartnerFormSubmitBtn.removeAttr('disabled')
      } else {
        this.$editPartnerFormSubmitBtn.attr('disabled', 'disabled')
      }
    })
  }
}
