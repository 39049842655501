export default class ImagePreview {
  constructor() {
    this.initJqueryVariables();
    this.eventRegister();
  }

  initJqueryVariables() {
    this.$imageModal = $('#imageModal');
    this.$imagePreviews = $('img.imagePreview');
  }

  eventRegister() {
    this.$imagePreviews.on("click", (e) => {
      this.$imageModal.find("img").attr('src', e.target.getAttribute("src"));
      this.$imageModal.modal('show');
    })
  }
}
