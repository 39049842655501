export default class Leads {
  constructor() {
    this.initJqueryVariables();
    this.eventRegister();
  }
  initJqueryVariables() {
    this.$uploadIpput = $('.leads-form input[type="file"]');
    this.$emailField = $('.wanna-be-alliance-partner #work_email');
    this.$phoneField = $('.wanna-be-alliance-partner #phone_number');
  }
  eventRegister() {
    this.$uploadIpput.on('change', (event) => {
      const input = event.target
      const fileSize = input.files[0].size / 1024 / 1024; // in MiB
      if (fileSize > 20) {
        alert('文件大小不可超过20M');
        input.value = '';
      }
    });

    this.$emailField.on('blur', (event) => {
      const input = event.target
      const regex = /^([_\-\.0-9a-zA-Z]+)@([_\-\.0-9a-zA-Z]+)\.([a-zA-Z]){2,7}$/;
      if (!regex.test(input.value)) {
        alert('请填写有效的电子邮件');
      }
    });

    this.$phoneField.on('blur', (event) => {
      const input = event.target
      const regex = /^1[3-9](\d{9})$/
      if (!regex.test(input.value)) {
        alert('手机格式错误');
      }
    });
  }
}
