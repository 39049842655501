export default class Users {
  constructor() {
    this.initJqueryVariables();
    this.eventRegister();
  }

  initJqueryVariables() {
    this.$newPassword = $('#new-password');
    this.$newPasswordConfirm = $('#new-password-confirm');
  }

  eventRegister() {
    $(document).on('click', '.user-index__btn-show-pwd', (e) => {
      const userId= e.currentTarget.dataset.userId;
      const passwordClass = `password${userId}`;
      $(`#${passwordClass}`).show();
      $(e.currentTarget).hide();
    })

    $('#users-reset-password__form').on('submit', (e) => {
      if (this.isBlank(this.$newPassword.val())) {
        alert('密码不能为空!');
        return false;
      } else if (this.$newPassword.val() != this.$newPasswordConfirm.val()) {
        alert('两次输入的密码不一致!');
        return false;
      }
    })
  }

  isBlank(str) {
    return (!str || /^\s*$/.test(str));
  }
}
